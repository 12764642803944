
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import { themes, Head } from 'mdx-deck'
import Box from './Box.js'
import clearFixImage from './images/clearfix.jpg'
import Grid from './images/grid.png'
import CssGrid from './images/css-grid.png'
export const theme = { ...themes.dark,
  ...themes.prism
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};
const Steps = makeShortcode("Steps");
const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Head mdxType="Head">
  <title>The evolution of grid systems on the web</title>
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:site" content="@smakosh" />
  <meta name="twitter:title" content="The evolution of grid systems on the web" />
  <meta name="twitter:description" content="A talk by Smakosh" />
  <meta name="twitter:image" content="https://2020.css-grids-talk.talks.smakosh.com/images/cover.png" />
    </Head>

    <h1>{`The evolution of grid systems on the web`}</h1>
    <h3>{`A talk by `}<a parentName="h3" {...{
        "href": "https://smakosh.com"
      }}>{`Smakosh`}</a></h3>
    <hr></hr>
    <h2>{`Who's Smakosh? on one hand`}</h2>
    <p>{`Smakosh LLC is one man software company that craftes software for startups using JavaScript tools like:`}</p>
    <ul>
      <li parentName="ul">{`Gatsby js`}</li>
      <li parentName="ul">{`Next js`}</li>
      <li parentName="ul">{`React Native`}</li>
      <li parentName="ul">{`etc...`}</li>
    </ul>
    <hr></hr>
    <h2>{`On the other hand`}</h2>
    <p>{`I'm Ismail Ghallou`}</p>
    <ul>
      <li parentName="ul">{`A self-taught JavaScript developer`}</li>
      <li parentName="ul">{`I work as a Full stack JavaScript developer at Maltem Africa`}</li>
      <li parentName="ul">{`Speaker`}</li>
      <li parentName="ul">{`Blogger`}</li>
      <li parentName="ul">{`Maker of `}<a parentName="li" {...{
          "href": "https://ai-hashtags.com"
        }}>{`Ai Hashtags`}</a>{` and other `}<a parentName="li" {...{
          "href": "https://github.com/smakosh"
        }}>{`open source tools`}</a></li>
    </ul>
    <hr></hr>
    <h2>{`What this talk is about?`}</h2>
    <ul>
      <li parentName="ul">{`CSS Grid systems`}</li>
      <li parentName="ul">{`The reason we need them`}</li>
      <li parentName="ul">{`Life before CSS`}</li>
      <li parentName="ul">{`Life after CSS and the clearfix hack`}</li>
      <li parentName="ul">{`Hello FlexBox`}</li>
      <li parentName="ul">{`Oh Hi CSS Grid`}</li>
    </ul>
    <hr></hr>
    <h2>{`What this talk isn't about?`}</h2>
    <ul>
      <li parentName="ul">{`Fundamentals and basics of CSS`}</li>
      <li parentName="ul">{`Doesn't recommend using CSS Grid, feel free to use what you think fits your use case`}</li>
      <li parentName="ul">{`CSS frameworks`}</li>
    </ul>
    <hr></hr>
    <h2>{`What are CSS Grid systems?`}</h2>
    <hr></hr>

    <Box mdxType="Box">
  A grid system is a structure that allows for content to be stacked both vertically and horizontally in a consistent and easily manageable fashion.
  <br /><br />Additionally, grid system code is project-agnostic giving it a high degree of portability so that it may be adopted on new projects.
    </Box>
    <blockquote>
      <p parentName="blockquote">{`Source: `}<a parentName="p" {...{
          "href": "https://www.sitepoint.com/understanding-css-grid-systems/"
        }}>{`Sitepoint`}</a></p>
    </blockquote>
    <hr></hr>
    <h2>{`Reasons we need them`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`They increase productivity`}</li>
        <li parentName="ul">{`Help a lot for responsive layouts`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h2>{`Life before CSS`}</h2>
    <p>{`In before, we were using HTML tables to code the UI`}</p>
    <blockquote>
      <p parentName="blockquote">{`We still do for email templates`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`Life after CSS`}</h2>
    <p>{`A month and 11 days after my birthday, CSS was initially released on December 17, 1996`}</p>
    <hr></hr>
    <h2>{`Let's all float but...`}</h2>
    <p>{`Then almost every developer started exploring CSS and migrating from HTML tables using CSS float but there was this issue`}</p>
    <hr></hr>

    <Box mdxType="Box">
  <img src={clearFixImage} alt="clearfix example" />
    </Box>
    <hr></hr>
    <h2>{`Clearfix hack`}</h2>
    <p>{`There has been a hack before but unfortunately it wasn't a general one until this came along`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.row:after {
  content: ""
  display: table
  clear: both
}
`}</code></pre>
    <hr></hr>
    <h2>{`Examples of a CSS float grid system`}</h2>
    <ul>
      <li parentName="ul">{`Bootstrap's 3 Grid`}</li>
      <li parentName="ul">{`Materialize CSS`}</li>
      <li parentName="ul">{`Unnamed CSS`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/Smakosh/pen/yRwOwB"
      }}>{`Example from scratch`}</a></p>
    <hr></hr>
    <h2>{`Hello FlexBox`}</h2>
    <p>{`Then few years later, FlexBox got introduced which is way better than using floats`}</p>
    <p>{`But it is only a 1 dimensional system`}</p>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/Smakosh/pen/MPZEwg"
      }}>{`How Bootstrap 4's Grid system is`}</a></p>
    <hr></hr>

    <Box mdxType="Box">
  <img src={Grid} alt="grid example" />
    </Box>
    <hr></hr>
    <h2>{`React Flex Ready`}</h2>
    <p>{`How I come up with React Flex Ready`}</p>
    <p><inlineCode parentName="p">{`max-width: \${({ col, gap = 1 }) => (col && col < 12 ? `}</inlineCode>{`\${100 * col / 12 - gap}%`}<inlineCode parentName="p">{` : "100%")};`}</inlineCode></p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/react-flex-ready-example-q6fdg"
      }}>{`Example`}</a></p>
    <hr></hr>
    <h2>{`Oh Hi CSS Grid`}</h2>
    <p>{`Finally CSS Grid came along which is a two dimensional grid-based layout system`}</p>
    <p>{`which changed the way we implement and even design our grid based user interfaces.`}</p>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/Smakosh/pen/EdMygm"
      }}>{`Simple example`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/Smakosh/pen/BwQBpW"
      }}>{`Complex example`}</a></p>
    <hr></hr>

    <Box mdxType="Box">
  <img src={CssGrid} alt="CSS grid example" />
    </Box>
    <hr></hr>
    <h2>{`Stretched cards use case`}</h2>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/Smakosh/pen/dwRVWK"
      }}>{`Stretched cards`}</a></p>
    <hr></hr>
    <h2>{`Thank you!`}</h2>
    <p>{`Feel free to ask me anything`}</p>
    <hr></hr>
    <h2>{`Referrences`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://css-tricks.com/snippets/css/complete-guide-grid/"
        }}>{`https://css-tricks.com/snippets/css/complete-guide-grid/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://printingcode.runemadsen.com/lecture-grid/"
        }}>{`http://printingcode.runemadsen.com/lecture-grid/`}</a></li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;